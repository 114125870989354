import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["safeCol", "parametersCol", "bankCol"];
    resizeTimeout = null;

    connect() {
        this.equalHeightAfterLoad();
        window.addEventListener("resize", this.handleResize.bind(this));
    }

    equalHeightAfterLoad() {
        window.addEventListener("load", () => {
            this.setEqualHeight();
        });
    }

    handleResize() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.setEqualHeight();
        }, 200);
    }

    setEqualHeight() {
        const parametersRows = Array.from(
            this.parametersColTarget.querySelectorAll(".bank-compare__row")
        );
        const safeRows = Array.from(
            this.safeColTarget.querySelectorAll(".bank-compare__row")
        );
        const bankRows = Array.from(
            this.bankColTarget.querySelectorAll(".bank-compare__row")
        );
        const headRows = Array.from(
            document.querySelectorAll(".bank-compare__head-row")
        );

        // Reset height for all rows in each column
        parametersRows.forEach(row => {
            row.style.height = "auto";
        });
        safeRows.forEach(row => {
            row.style.height = "auto";
        });
        bankRows.forEach(row => {
            row.style.height = "auto";
        });

        // Set the maximum height for corresponding rows in each column
        parametersRows.forEach((row, index) => {
            const safeRow = safeRows[index];
            const bankRow = bankRows[index];

            const maxHeight = Math.max(
                row.offsetHeight,
                safeRow.offsetHeight,
                bankRow.offsetHeight
            );

            row.style.height = maxHeight + "px";
            safeRow.style.height = maxHeight + "px";
            bankRow.style.height = maxHeight + "px";
        });

        // Reset height for all head rows
        headRows.forEach(row => {
            row.style.height = "auto";
        });

        // Find the maximum height among head rows
        const maxHeadRowHeight = Math.max(
            ...headRows.map(row => row.offsetHeight)
        );

        // Set the maximum height for head rows
        headRows.forEach(row => {
            row.style.height = maxHeadRowHeight + "px";
        });
    }
}
