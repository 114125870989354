/*
 * This is an controller for navbar
 * Any element with a data-controller="navbar" attribute will cause this controller to be executed.
 */

import { Controller } from "@hotwired/stimulus";
import gsap from "./gsap-wrapper";

export default class extends Controller {
    static targets = ["header"];

    connect() {}

    // Function to toggle the mobile navigation
    toggleMobileNav(event) {
        const mobileNavTarget = this.findMobileNavTarget();
        const button = event.currentTarget;
        const body = document.querySelector("body");

        if (mobileNavTarget.classList.contains("menu-active")) {
            // Slide the mobile navigation from the right and fade it out
            gsap.to(mobileNavTarget, {
                x: "100%",
                opacity: 0,
                duration: 0.5,
                ease: "power2.inOut",
                onStart: () => {
                    body.style.overflow = "auto"; // Enable scrolling
                    button.classList.remove("active");
                },
                onComplete: () => {
                    mobileNavTarget.style.display = "none";
                }
            });
        } else {
            mobileNavTarget.style.display = "flex";
            mobileNavTarget.style.position = "fixed";
            mobileNavTarget.style.opacity = "0";
            mobileNavTarget.style.transform = "translateX(100%)";

            // Slide the mobile navigation from the right and fade it in
            gsap.to(mobileNavTarget, {
                x: 0,
                opacity: 1,
                duration: 0.5,
                ease: "power2.inOut",
                onStart: () => {
                    body.style.overflow = "hidden"; // Disable scrolling at the start of animation
                    button.classList.add("active");
                }
            });
        }

        mobileNavTarget.classList.toggle("menu-active");
    }

    // Function to toggle the language dropdown
    toggleLanguageDropdown(event) {
        const dropdownMenu = event.currentTarget.parentNode.querySelector(
            "#languageDropdown"
        );

        // If the dropdown is hidden, open it. Otherwise, close it.
        if (dropdownMenu.classList.contains("hidden")) {
            openDropdown();
        } else {
            closeDropdown();
        }

        // Open the dropdown
        function openDropdown() {
            gsap.set(dropdownMenu, { display: "block", opacity: 0, y: -10 });
            gsap.to(dropdownMenu, {
                opacity: 1,
                y: 0,
                duration: 0.3,
                onComplete: () => {
                    dropdownMenu.classList.remove("hidden");
                    document.addEventListener("click", closeDropdownOnClick);
                    document.addEventListener("keydown", closeDropdownOnEsc);
                }
            });
        }

        // Close the dropdown
        function closeDropdown() {
            gsap.to(dropdownMenu, {
                opacity: 0,
                y: -10,
                duration: 0.3,
                onComplete: () => {
                    dropdownMenu.classList.add("hidden");
                    dropdownMenu.style.display = "none";
                    document.removeEventListener("click", closeDropdownOnClick);
                    document.removeEventListener("keydown", closeDropdownOnEsc);
                }
            });
        }

        // Close the dropdown when clicking outside of it
        function closeDropdownOnClick(e) {
            if (!dropdownMenu.contains(e.target)) {
                closeDropdown();
            }
        }

        // Close the dropdown when pressing the escape key
        function closeDropdownOnEsc(e) {
            if (e.key === "Escape") {
                closeDropdown();
            }
        }
    }

    // Function to find the mobile navigation target element
    findMobileNavTarget() {
        return document.querySelector(".js-mobile-nav");
    }
}
