import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        successMsg: String,
        errorMsg: String
    };
    connect() {}

    async submit(e) {
        e.preventDefault();

        const formData = new FormData(this.element);
        const object = {};

        formData.forEach(function(value, key) {
            object[key] = value == "on" ? true : value;
        });

        const submitButton = document.querySelector(".submit-button button");
        submitButton.setAttribute("disabled", "disabled");

        let loadingTimeout = setTimeout(() => {
            showNotification(
                "Zpráva se odesílá, vyčkejte prosím...",
                "loading"
            );
        }, 4000);

        /**
         * ? Add an subject to the message, if the formSubject is defined in the URL
         */
        if (window.location.search.includes("formSubject")) {
            const urlParams = new URLSearchParams(window.location.search);
            object.subject = urlParams.get("formSubject");
        }

        fetch(this.element.getAttribute("action"), {
            method: "POST",
            body: JSON.stringify(object)
        })
            .then(res => {
                clearTimeout(loadingTimeout); // Clear the loading timeout
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res.status);
                }
            })
            .then(data => {
                showNotification(this.successMsgValue, "success");
                submitButton.removeAttribute("disabled");
                console.log(data);
                this.element.reset();

                //send data to analytics
                dataLayer.push({
                    event: "form_submission",
                    formLocation: window.location.path
                });
            })
            .catch(error => {
                showNotification(this.errorMsgValue, "error");
                submitButton.removeAttribute("disabled");
                console.log("Error:", error);
                this.element.reset();
            });

        function showNotification(message, type) {
            const notificationElement = document.querySelector(".notification");
            const notificationBackground = document.querySelector(
                ".notification__overlay"
            );
            const bgNotification = document.querySelector(
                ".notification__button"
            );

            notificationElement.textContent = message;
            notificationElement.classList.add(type);
            notificationElement.classList.remove("hidden");

            bgNotification.classList.remove("hidden");
            bgNotification.classList.add("notification__button__overlay");

            notificationBackground.classList.remove("hidden");
            notificationBackground.classList.add("notification__overlay__open");

            void notificationElement.offsetWidth;
            void notificationBackground.offsetWidth;
            void bgNotification.offsetWidth;

            bgNotification.classList.add("fade-in");
            notificationElement.classList.add("fade-in");

            setTimeout(() => {
                hideNotification(
                    notificationElement,
                    notificationBackground,
                    bgNotification
                );
            }, 2500);
        }

        function hideNotification(
            notificationElement,
            notificationBackground,
            bgNotification
        ) {
            notificationElement.classList.add("fade-out");
            bgNotification.classList.add("fade-out");

            setTimeout(() => {
                notificationElement.classList.add("hidden");
                notificationElement.classList.remove(
                    "loading",
                    "success",
                    "error",
                    "fade-in",
                    "fade-out"
                );

                notificationBackground.classList.add("hidden");
                notificationBackground.classList.remove(
                    "notification__overlay__open",
                    "fade-in",
                    "fade-out"
                );

                bgNotification.classList.add("hidden");
                bgNotification.classList.remove(
                    "notification__button__overlay",
                    "fade-in",
                    "fade-out"
                );
            }, 800);
        }
    }
}
