/*
 * This is the controller for the footer.
 * Any element with a data-controller="footer" attribute will cause this controller to be executed.
 */

import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

export default class extends Controller {
    static targets = ["footerCol"];

    connect() {
        this.initializeIntersectionObserver();
    }

    initializeIntersectionObserver() {
        // Options for the Intersection Observer
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: "-50px", // Add a margin of -50px around the root (to trigger earlier)
            threshold: 0.2 // Adjust the threshold as per your requirements
        };

        // Create an Intersection Observer
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.slideFooterCol(entry.target);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        // Observe each footer column element
        const footerCols = this.footerColTargets;
        footerCols.forEach(col => {
            observer.observe(col); // Start observing the column
            gsap.set(col, { y: -50, opacity: 0 }); // Set initial position and opacity of the column
        });
    }

    slideFooterCol(col) {
        // Slide the footer column into view
        gsap.to(col, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: "power2.out"
        });
    }
}
