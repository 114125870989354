import { Controller } from "@hotwired/stimulus";

// import Swiper bundle with all modules installed
import Swiper from "swiper-old/bundle";

// import styles bundle
import "swiper/css/bundle";

export default class extends Controller {
    static targets = ["boxesChooserSwiper", "swiperPagination"];

    connect() {
        this.urlParameters = this.getURLParameters(window.location.href);
        this.initSwiper();
    }

    initSwiper() {
        let initialSlide = 2;

        let autoplay = {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true
        };

        if (this.urlParameters.box) {
            const box = this.urlParameters.box.replace(
                "#boxes-chooser",
                ""
            );
            initialSlide = this.getBoxSlideIndex(box) ?? 2;
            autoplay = false;
        }

        var boxesChooser = new Swiper(this.boxesChooserSwiperTarget, {
            centeredSlides: true,
            slidesPerView: 2.5,
            touchRatio: 0.2,
            autoplay: autoplay,
            slideToClickedSlide: true,
            loop: true,
            loopedSlides: 8,
            spaceBetween: 25,
            initialSlide: initialSlide,
            breakpoints: {
                801: {
                    slidesPerView: 5,
                    spaceBetween: 35
                }
            },
            pagination: {
                el: this.swiperPaginationTarget,
                clickable: true
            }
        });
    }

    buttonClick(event) {
        event.preventDefault();

        var swiperSlide = event.target.closest(".swiper-slide");

        // Get the value from the data-box attribute
        var dataBoxValue = swiperSlide.dataset.box;

        // Find the input element within the swiper-slide
        var input = document.querySelector('input[name="BoxTypeId"]');

        // Set the value of the input
        if (input && dataBoxValue) {
            input.value = dataBoxValue;

            // Find the form with id orderForm
            var form = document.getElementById("orderForm");

            const languages = {
                cz: "cs-CZ",
                en: "en-GB",
                de: "de-DE",
                ru: "ru-RU",
                it: "it-IT",
                vi: "vi-VN"
            };

            // Find the input element with name "language"
            var languageInput = form.querySelector('input[name="language"]');

            // Check if the languageInput value is a valid language key in languages map
            if (
                languageInput &&
                languages.hasOwnProperty(languageInput.value)
            ) {
                languageInput.value = languages[languageInput.value]; // Set the value of the input
            }

            //if param clientref exist create cookie 24safe_clientref
            if (this.urlParameters.clientref) {
                const parameter = this.urlParameters.clientref.replace(
                    "#boxes-chooser",
                    ""
                );
                this.setCookie("24safe_clientref", parameter, 1);
            }

            // Submit the form
            if (form) {
                form.submit();
            }
        }
    }

    getURLParameters(url) {
        // Get the query string part of the URL
        let queryString = url.split("?")[1];

        let parameters = {};

        if (queryString) {
            // Split the query string into individual key-value pairs
            var pairs = queryString.split("&");

            pairs.forEach(function(pair) {
                // Split the pair into key and value
                var keyValue = pair.split("=");
                // Decode the key and value, and store them in the parameters object
                parameters[
                    decodeURIComponent(keyValue[0])
                ] = decodeURIComponent(keyValue[1] || "");
            });
        }

        return parameters;
    }

    setCookie(cookieName, cookieValue, expirationDays) {
        const d = new Date();
        d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
        const expires = "expires=" + d.toUTCString();
        const domain = ";domain=.24safe.cz";
        document.cookie =
            cookieName + "=" + cookieValue + ";" + expires + ";path=/" + domain;
    }

    getBoxSlideIndex(box) {
        const indexes = {
            s: 0,
            m: 1,
            l: 2,
            xl: 3,
            xxl: 4
        };

        return indexes[box];
    }
}
