import { Controller } from "@hotwired/stimulus";
import gsap from "./gsap-wrapper";

import { Power1, CSSPlugin } from "gsap/all";
gsap.registerPlugin(Power1, CSSPlugin);

import Swiper from "swiper/bundle";

import "swiper/css/bundle";

/*
 * This is an controller for opening-carousel
 * It is used to initialize the Swiper carousel, animating content, slides behaviour, etc.
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static targets = [
        "mainSwiperWrap",
        "swiperPagination",
        "swiperButtonPrev",
        "swiperButtonNext",
        "singleSwiperSlide",
        "swiperPreloader"
    ];

    connect() {
        // On connect init swiper
        this.initializeSwiper(this.mainSwiperWrapTarget);

        // Init swiper after load
        this.initSwiperAfterLoad();
    }

    initializeSwiper(element) {
        const openingSwiper = new Swiper(element, {
            // Optional parameters
            loop: true,
            slidesPerView: 1,
            fade: { crossFade: true },
            freeMode: false,

            // If we need pagination
            pagination: {
                el: this.swiperPaginationTarget,
                clickable: true
            },

            lazy: true,

            navigation: {
                nextEl: this.swiperButtonNextTarget,
                prevEl: this.swiperButtonPrevTarget
            },

            preventInteractionOnTransition: true,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            init: false,

            on: {
                init: () => {
                    this.animateContentCol();
                    this.animateHexagonImage();
                    this.animateBigBoxSlide();
                },

                slideChangeTransitionStart: () => {
                    this.animateContentCol();
                    this.zoomSlideBg();
                },

                transitionStart: () => {
                    if (openingSwiper.realIndex === 0) {
                        this.animateBigBoxSlide();
                    }

                    if (openingSwiper.realIndex === 1) {
                        this.animateSlideZero();
                    }

                    if (openingSwiper.realIndex === 2) {
                        this.animateSlideOne();
                    }

                    if (openingSwiper.realIndex === 3) {
                        this.animateSlideTwo();
                    }

                    if (openingSwiper.realIndex === 4) {
                        this.animateSlideThree();
                    }

                    if (openingSwiper.realIndex === 5) {
                        this.animateSlideFour();
                    }

                    this.animateHexagonImage();
                }
            }
        });

        return openingSwiper;
    }

    initSwiperAfterLoad() {
        window.addEventListener("load", () => {
            const swiperPreloader = this.swiperPreloaderTarget;

            const overlays = document.querySelectorAll(
                "#opening-carousel-background-overlay"
            );

            overlays.forEach(overlay => {
                gsap.to(overlay, {
                    autoAlpha: 100,
                    duration: 0.5,
                    ease: Power1.easeOut
                });
            });

            gsap.to(swiperPreloader, {
                autoAlpha: 0,
                duration: 0.15,
                delay: 0.1,
                ease: Power1.easeOut
            });

            console.log("loaded, initializaing swiper");

            const openingSwiper = this.initializeSwiper(
                this.mainSwiperWrapTarget
            );
            openingSwiper.init();
        });
    }

    zoomSlideBg = () => {
        const swiper = this.mainSwiperWrapTarget.swiper;
        const realIndex = swiper.realIndex;
        const previousIndex = swiper.previousIndex;

        console.log("realCurrentIndex" + realIndex);
        console.log("previousIndex" + previousIndex);

        const realSlide = swiper.slides[realIndex];
        const previousSlide = swiper.slides[previousIndex];

        if (window.innerWidth > 1280) {
            gsap.set(previousSlide, {
                opacity: 1
            });

            gsap.set(realSlide, {
                opacity: 0
            });

            gsap.to(realSlide, {
                opacity: 1,
                duration: 1,
                ease: Power1.easeIn,

                onComplete: () => {
                    gsap.to(previousSlide, {
                        opacity: 0,
                        duration: 0.75,
                        ease: Power1.easeInOut
                    });
                }
            });
        }
    };

    animateHexagonBottomImage() {
        /* Function for animating the hexagon bottom image */

        const pathElements = document.querySelectorAll(
            "[class^='js-hexagon-path-']"
        );

        function animatePathElements() {
            pathElements.forEach((pathElement, index) => {
                gsap.fromTo(
                    pathElement,
                    {
                        opacity: 0.1
                    },
                    {
                        opacity: 0.2,
                        repeat: -1,
                        yoyo: true,
                        duration: 1.25,
                        delay: index * 1.2, // Delay each element by 1.2 seconds
                        onComplete: () => {
                            if (index === pathElements.length - 1) {
                                // If the last element has completed, restart the animation
                                animatePathElements();
                            }
                        }
                    }
                );
            });
        }

        // Check if the window width is larger than 1280px
        if (window.innerWidth > 1280) {
            animatePathElements();
        }
    }

    animateContentCol() {
        const activeSlide = this.mainSwiperWrapTarget.swiper.slides[
            this.mainSwiperWrapTarget.swiper.activeIndex
        ];
        const contentCol = activeSlide.querySelector(
            ".opening-carousel__single-slide-left-content-col"
        );

        const hexagonText = activeSlide.querySelector(
            ".opening-carousel__hexagon-text"
        );

        if (window.innerWidth > 1280) {
            gsap.set(hexagonText, {
                autoAlpha: 0
            });

            gsap.fromTo(
                hexagonText,
                {
                    rotateX: 10,
                    scale: 0.98,
                    autoAlpha: 0.45
                },
                {
                    scale: 1,
                    rotateX: 0,
                    autoAlpha: 1,
                    duration: 0.8,
                    delay: 0.5
                }
            );
        }

        gsap.fromTo(
            contentCol,
            {
                opacity: 0,
                x: -75,
                delay: 0,
                ease: "none"
            },
            {
                opacity: 1,
                x: 0,
                delay: 0.75,
                duration: 1.25,
                ease: "sine.out"
            }
        );
    }

    animateSlideZero() {
        const loopIndex = 1; // Set the desired data-loop-index value

        const slide = document.querySelector(
            `[data-loop-index="${loopIndex}"]`
        );

        const wholePartials = slide.querySelector(
            ".opening-carousel__zero-slide-partials"
        );

        const hexagonText = slide.querySelector(
            ".opening-carousel__hexagon-text"
        );

        gsap.fromTo(
            wholePartials,
            {
                autoAlpha: 0,
                y: -20
            },
            {
                autoAlpha: 1,
                x: 0,
                duration: 0.35,
                delay: 0.1
            }
        );

        gsap.fromTo(
            hexagonText,
            {
                autoAlpha: 0
            },
            {
                autoAlpha: 1,
                x: 0,
                duration: 0.35
            }
        );

        const imageWrap = slide.querySelector(".opening-carousel__image-wrap");

        const dotsSVG = slide.querySelector(
            ".opening-carousel__zero-slide-svgDots svg"
        );
        const firstSlideCircles = document.querySelector(
            ".opening-carousel__zero-slide-circles"
        );

        gsap.to(firstSlideCircles, {
            opacity: 1
        });

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 0.75,
            scale: 1,
            x: 0,
            y: 0,
            duration: 0
        });

        tl.to(imageWrap, {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            duration: 1.75
        });

        gsap.fromTo(
            dotsSVG,
            {
                height: 0
            },
            {
                height: 100,
                duration: 2.75,
                yoyo: true,
                repeat: -1
            }
        );
    }

    animateSlideOne() {
        const loopIndex = 2; // Set the desired data-loop-index value
        const slide = document.querySelector(
            `[data-loop-index="${loopIndex}"]`
        );

        const imageWrap = slide.querySelector(".opening-carousel__image-wrap");

        const image = slide.querySelector(".opening-carousel__image-wrap img");

        const slideLogo = slide.querySelector(
            ".opening-carousel__first-slide-logo"
        );

        const firstSlideGroup = slide.querySelector(
            ".opening-carousel__first-slide-partials"
        );

        var slideLogoTimeline = gsap.timeline({});

        slideLogoTimeline.set(slideLogo, {
            opacity: 0.35,
            ease: "none"
        });

        slideLogoTimeline.to(slideLogo, {
            opacity: 0.85,
            yoyo: false,
            delay: 1.5,
            duration: 2.35,
            repeat: -1,
            ease: Power1.easeInOut
        });

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 1,
            ease: "none"
        });

        tl.to(imageWrap, {
            opacity: 1,
            ease: Power1.easeIn
        });

        var imageTl = gsap.timeline({});

        if (window.innerWidth > 1280) {
            imageTl.set(image, {
                autoAlpha: 0.05,
                x: -100,
                xPercent: 30,
                ease: "none"
            });

            imageTl.to(image, {
                xPercent: 0,
                x: 0,
                autoAlpha: 1,
                duration: 2.35,
                ease: Power1.easeIn
            });
        } else {
            imageTl.set(image, {
                autoAlpha: 0.05,
                right: -100,
                xPercent: 30,
                ease: "none"
            });

            imageTl.to(image, {
                xPercent: 0,
                right: 0,
                autoAlpha: 1,
                duration: 2.85,
                ease: Power1.easeIn
            });
        }

        firstSlideGroup.addEventListener("mouseover", () => {
            slideLogoTimeline.pause();
            slideLogo.style.opacity = 1;
        });

        firstSlideGroup.addEventListener("mouseout", () => {
            slideLogo.style.opacity = 0.95;
            slideLogoTimeline.play();
        });
    }

    animateSlideTwo() {
        const loopIndex = 3; // Set the desired data-loop-index value
        const slide = document.querySelector(
            `[data-loop-index="${loopIndex}"]`
        );

        const imageWrap = slide.querySelector(".opening-carousel__image-wrap");

        const secondSlidePratials = slide.querySelector(
            ".opening-carousel__second-slide-partials"
        );

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 0.45,
            scale: 0.95,
            x: 50,
            rotateY: 40,
            y: 0,
            duration: 0
        });

        tl.to(imageWrap, {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            rotateY: 0,
            duration: 1.75,
            delay: 0.2
        });

        var secondSlidePartialsTimeLine = gsap.timeline({});

        secondSlidePartialsTimeLine.set(secondSlidePratials, {
            opacity: 0,
            y: 50
        });

        secondSlidePartialsTimeLine.to(secondSlidePratials, {
            opacity: 1,
            y: 0,
            duration: 1.45,
            delay: 1.75
        });
    }

    animateSlideThree() {
        const loopIndex = 4; // Set the desired data-loop-index value
        const slide = document.querySelector(
            `[data-loop-index="${loopIndex}"]`
        );

        const imageWrap = slide.querySelector(".opening-carousel__image-wrap");

        const thirdSlidePratials = slide.querySelector(
            ".opening-carousel__third-slide-partials"
        );

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 0.45,
            scale: 0.95,
            x: 50,
            rotateY: 40,
            y: 0,
            duration: 0
        });

        tl.to(imageWrap, {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            rotateY: 0,
            duration: 1.75,
            delay: 0.2
        });

        var thirdSlidePartialsTimeLine = gsap.timeline({});

        thirdSlidePartialsTimeLine.set(thirdSlidePratials, {
            opacity: 0,
            y: 50
        });

        thirdSlidePartialsTimeLine.to(thirdSlidePratials, {
            opacity: 1,
            y: 0,
            duration: 1.45,
            delay: 1.75
        });
    }

    animateSlideFour() {
        const loopIndex = 5; // Set the desired data-loop-index value
        const slide = document.querySelector(
            `[data-loop-index="${loopIndex}"]`
        );

        const imageWrap = slide.querySelector(".opening-carousel__image-wrap");

        const fourthSlidePratials = slide.querySelector(
            ".opening-carousel__fourth-slide-partials"
        );

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 0.45,
            scale: 0.95,
            x: 50,
            rotateY: 40,
            y: 0,
            duration: 0
        });

        tl.to(imageWrap, {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            rotateY: 0,
            duration: 1.75,
            delay: 0.2
        });

        var fourthSlidePartialsTimeLine = gsap.timeline({});

        fourthSlidePartialsTimeLine.set(fourthSlidePratials, {
            opacity: 0,
            y: 50
        });

        fourthSlidePartialsTimeLine.to(fourthSlidePratials, {
            opacity: 1,
            y: 0,
            duration: 1.45,
            delay: 1.75
        });
    }

    animateHexagonImage() {
        const activeSlide = this.mainSwiperWrapTarget.swiper.slides[
            this.mainSwiperWrapTarget.swiper.activeIndex
        ];

        const hexagonImage = activeSlide.querySelector(
            ".opening-carousel__hexagon-image"
        );

        if (hexagonImage) {
            const hexagonImageBottom = activeSlide.querySelector(
                ".opening-carousel__hexagon-image-bottom"
            );

            if (window.innerWidth > 1280) {
                gsap.set(hexagonImage, {
                    autoAlpha: 0
                });

                gsap.set(hexagonImageBottom, {
                    autoAlpha: 0
                });

                gsap.fromTo(
                    hexagonImage,
                    {
                        rotateX: 10,
                        scale: 0.98,
                        autoAlpha: 0.45
                    },
                    {
                        scale: 1,
                        rotateX: 0,
                        autoAlpha: 1,
                        duration: 0.8,
                        delay: 0.5
                    }
                );

                gsap.fromTo(
                    hexagonImageBottom,
                    {
                        rotateX: 10,
                        scale: 0.98,
                        autoAlpha: 0.45
                    },
                    {
                        scale: 1,
                        rotateX: 0,
                        autoAlpha: 1,
                        duration: 0.8,
                        delay: 0.5
                    }
                );
            }
        }
    }

    zeroSlideNextSlide() {
        const slideBg = document.querySelector(
            ".opening-carousel__slide-bg-image-1"
        );

        const firstSlideCircles = document.querySelector(
            ".opening-carousel__zero-slide-circles"
        );

        const swiper = this.mainSwiperWrapTarget.swiper;

        gsap.fromTo(
            slideBg,
            { scale: 1, ease: "none", autoAlpha: 1 },
            {
                scale: 1.25,
                y: "25",
                x: "-25",
                duration: 3.2,
                delay: 0.1,
                autoAlpha: 0.95,
                ease: Power1.easeOut,
                onStart: () => {
                    gsap.delayedCall(1.15, () => {
                        swiper.slideNext();
                        console.log("called next slide");
                    });
                },
                onComplete: () => {
                    gsap.to(slideBg, {
                        scale: 1,
                        x: 0,
                        y: 0,
                        delay: 2,
                        autoAlpha: 1
                    });
                }
            }
        );

        gsap.to(firstSlideCircles, {
            opacity: 0,
            duration: 0.1
        });
    }

    firstSlideNextSlide() {
        const secondSlideBgs = document.querySelectorAll(
            ".opening-carousel__slide-bg-image-0, .opening-carousel__slide-bg-image-2"
        );

        const swiper = this.mainSwiperWrapTarget.swiper;

        secondSlideBgs.forEach(secondSlideBg => {
            gsap.fromTo(
                secondSlideBg,
                { scale: 1, ease: "none", autoAlpha: 1 },
                {
                    scale: 1.15,
                    duration: 3.2,
                    delay: 0.1,
                    autoAlpha: 0.95,
                    ease: Power1.easeOut,
                    onStart: () => {
                        gsap.delayedCall(1.15, () => {
                            swiper.slideNext();
                            console.log("called next slide");
                        });
                    },
                    onComplete: () => {
                        gsap.to(secondSlideBg, {
                            scale: 1,
                            delay: 2,
                            autoAlpha: 1
                        });
                    }
                }
            );
        });
    }

    animateBigBoxSlide() {
        const loopIndex = 0; // Set the desired data-loop-index value

        const slide = document.querySelector(
            `[data-loop-index="${loopIndex}"]`
        );

        console.log("???");

        console.log(slide);

        const imageWrap = slide.querySelector(".opening-carousel__image-wrap");

        const slideLogo = slide.querySelector(
            ".opening-carousel__first-slide-logo"
        );

        const newsRibbon = slide.querySelector(
            ".opening-carousel__news-ribbon"
        );

        var ribbonTimeline = gsap.timeline({});
        ribbonTimeline.set(newsRibbon, {
            opacity: 0,
            x: "-50%",
            scale: 0.8
        });

        ribbonTimeline.to(newsRibbon, {
            opacity: 1,
            x: "0%",
            scale: 1,
            duration: 1.5,
            ease: "power2.out"
        });

        console.log(slideLogo);

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 0.75,
            scale: 0.95,
            x: 20,
            y: 30,
            duration: 0
        });

        tl.to(imageWrap, {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            duration: 1.75,
            delay: 0.2
        });

        const firstSlideGroup = slide.querySelector(
            ".opening-carousel__first-slide-partials"
        );

        var slideLogoTimeline = gsap.timeline({});

        slideLogoTimeline.set(slideLogo, {
            opacity: 0.35,
            ease: "none"
        });

        slideLogoTimeline.to(slideLogo, {
            opacity: 0.85,
            yoyo: false,
            delay: 1.5,
            duration: 2.35,
            repeat: -1,
            ease: Power1.easeInOut
        });

        var tl = gsap.timeline({});
        tl.set(imageWrap, {
            opacity: 1,
            ease: "none"
        });

        firstSlideGroup.addEventListener("mouseover", () => {
            slideLogoTimeline.pause();
            slideLogo.style.opacity = 1;
        });

        firstSlideGroup.addEventListener("mouseout", () => {
            slideLogo.style.opacity = 0.95;
            slideLogoTimeline.play();
        });
    }

    previousSlide() {
        const swiper = this.mainSwiperWrapTarget.swiper;

        swiper.slidePrev();
    }

    nextSlide() {
        const swiper = this.mainSwiperWrapTarget.swiper;

        swiper.slideNext();
    }

    slideToThird() {
        const swiper = this.mainSwiperWrapTarget.swiper;

        swiper.slideTo(3);
    }

    bigBoxButtonClick(e) {
        e.preventDefault(); // ? Prevent the default behavior of the link (e.g., following the href immediately).

        // ? Find the <a> link inside the clicked element.
        const link = e.currentTarget.querySelector("a");

        if (!link) return; // ?  If no link exists inside the current element, exit the function.

        // ?  Create a URL object based on the href of the found link.
        const url = new URL(link.href, window.location.origin);

        // ?  Check if the hash part of the URL points to `#contact-form`.
        if (url.hash === "#contact-form") {
            // ?  Add a query parameter `formSubject=bigbox` to the URL.
            url.searchParams.set("formSubject", "bigbox");

            // ?  Update the browser's URL without reloading the page.
            window.history.pushState({}, "", url);

            // ?  Scroll to the element with the ID `contact-form`.
            const targetElement = document.querySelector("#contact-form");
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" }); // Smoothly scroll to the element.
            }
        }
    }
}
