import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["canvas"];

    connect() {
        this.ctx = this.canvasTarget.getContext("2d");
        this.width = this.canvasTarget.width = 120;
        this.height = this.canvasTarget.height = 107;
        this.fps = 30;
        this.sejfik = new Image();
        this.frameCount = 51;
        this.sheetWidth = 6118;
        this.frameWidth = this.sheetWidth / this.frameCount;
        this.frameHeight = 107;
        this.lastTick = null;
        this.play = true;
        this.currentFrame = 0;

        this.sejfik.src = "/build/img/mascot/sejfik_spritesheet.png";

        this.animate();
    }

    animate() {
        
        if (!this.lastTick) this.lastTick = performance.now();

        const now = performance.now();
        const delta = Math.round(now - this.lastTick);

        if (delta >= 1000 / this.fps) {
            this.lastTick = now;

            this.ctx.clearRect(0, 0, this.width, this.height);

            if (this.currentFrame == this.frameCount) {
                this.currentFrame = 0;
                this.play = false;
                setTimeout(this.resetPlay.bind(this), 5000);
            }

            const posX = this.currentFrame * this.frameWidth;

            this.ctx.drawImage(
                this.sejfik,
                posX,
                0,
                this.frameWidth,
                this.frameHeight,
                0,
                0,
                this.frameWidth,
                this.frameHeight
            );

            if (this.play) this.currentFrame++;
        }

        requestAnimationFrame(this.animate.bind(this));
    }

    resetPlay() {
        this.play = true;
    }
}
