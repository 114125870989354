import { Controller } from "@hotwired/stimulus";

// import Swiper bundle with all modules installed
import Swiper from "swiper-old/bundle";

// import styles bundle
import "swiper/css/bundle";

export default class extends Controller {
    static targets = ["mainSwiperWrap", "swiperPagination", "scrollSvg"];

    connect() {
        this.initializeSwiper(this.mainSwiperWrapTarget);
    }

    initializeSwiper(element) {
        const pageBannerSwiper = new Swiper(element, {
            // Optional parameters
            loop: false,
            slidesPerView: 1,
            freeMode: false,

            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true
            },

            // If we need pagination
            pagination: {
                el: this.swiperPaginationTarget,
                clickable: true
            },

            preventInteractionOnTransition: true,
            effect: "fade"
        });
    }
}
