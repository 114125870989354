import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
    }

    showCookie(e) {
        e.preventDefault();

        document.dispatchEvent(new CustomEvent('showCookie'));
    }
}
