import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["videoContainer"];

    connect() {
        console.log('Connected to video controller.');
        document.addEventListener('keydown', this.handleEscapeKey.bind(this));
    }

    disconnect() {
        console.log('Disconnected from video controller.');
        document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
    }

    handleEscapeKey(event) {
        console.log('Keydown event detected.', event.key || event.keyCode);
        if (event.key === 'Escape' || event.keyCode === 27) {
            console.log('Escape key pressed.');

            let videoElement = document.body.querySelector('.videoPlaying');

            if (videoElement) {
                console.log('Found a video playing, attempting to close.');
                this.closeVideo(videoElement);
            } else {
                console.log('No video found playing.');
            }
        }
    }

    toggleVideo(event) {
        console.log('buttonClicked');
        event.preventDefault();

        let triggerElement = event.currentTarget;
        let videoIndex = triggerElement.dataset.videoButton;

        if (!videoIndex) {
            console.error('No video button data attribute found!');
            return;
        }

        let videoElement = this.videoContainerTargets.find(video => video.dataset.loopIndex === videoIndex);

        if (!videoElement) {
            console.error(`No video element found for index ${videoIndex}!`);
            return;
        }

        if (!videoElement.classList.contains('videoPlaying')) {
            console.log('Video is not playing, attempting to open.');
            this.openVideo(videoElement);
        } else {
            console.log('Video is playing, attempting to close.');
            this.closeVideo(videoElement);
        }
    }

    openVideo(videoElement) {
        console.log('openVideo method called.');

        videoElement.originalParent = videoElement.parentElement;

        document.body.appendChild(videoElement);

        let video = videoElement.querySelector('video');
        if (video) {
            video.play();
        }

        videoElement.classList.add('videoPlaying');
        videoElement.classList.remove('videoHiding');

        // Attach event listener to close button
        let closeButton = document.querySelector(`[data-video-close="${videoElement.dataset.loopIndex}"]`);
        if (closeButton) {
            closeButton.addEventListener('click', this.closeButtonHandler.bind(this, videoElement));
        }
    }

    closeVideo(videoElement) {
        console.log('closeVideo method called.');
        videoElement.classList.add('videoHiding');

        let closeButton = document.querySelector(`[data-video-close="${videoElement.dataset.loopIndex}"]`);
        if (closeButton) {
            closeButton.removeEventListener('click', this.closeButtonHandler.bind(this, videoElement));
        }

        let video = videoElement.querySelector('video');
        if (video) {
            setTimeout(() => {
                video.pause();
            }, 2000); // Pause after the animation
        }

        setTimeout(() => {
            if (videoElement.originalParent) {
                videoElement.originalParent.appendChild(videoElement);
            }

            videoElement.classList.remove('videoPlaying', 'videoHiding');
        }, 2000);
    }

    closeButtonHandler(videoElement, event) {
        event.preventDefault();
        this.closeVideo(videoElement);
    }
}
