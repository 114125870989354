/*
 * Layout controller for reccuring elements animations
 */

import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Power1 } from "gsap/all";

gsap.registerPlugin(Power1, ScrollTrigger);

export default class extends Controller {
    connect() {
        this.animateSmallerHexagons();
    }

    animateSmallerHexagons() {
        const smallHexagons = document.querySelectorAll(
            ".js-animate-floating-hexagons-smaller"
        );

        smallHexagons.forEach(hexagon => {
            const image = hexagon.querySelector("img");

            gsap.from(image, {
                duration: 1.75,
                top: 200,
                ease: Power1.easeOut,
                scrollTrigger: {
                    trigger: image,
                    start: "top 80%",
                    end: "bottom 50%",
                    scrub: 1
                }
            });
        });
    }
}
