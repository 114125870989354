import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {}

    scrollToNextSection(event) {
        event.preventDefault();

        const parentSection = this.element.closest("section");

        const nextSibling = parentSection.nextElementSibling;

        if (nextSibling) {
            nextSibling.scrollIntoView({ behavior: "smooth" });
        }
    }
}
