import { Controller } from "@hotwired/stimulus";

// import Swiper bundle with all modules installed
import Swiper from "swiper-old/bundle";

// import styles bundle
import "swiper/css/bundle";

export default class extends Controller {
    static targets = ["goldFeverSwiper", "swiperPagination"];

    connect() {
        this.initSwiper();
        this.goldForm = document.getElementById("gold-form");
        if (!this.goldForm) return;

        this.goldNameInput = this.goldForm.querySelector(
            'input[name="goldName"]'
        );
        this.goldPriceInput = this.goldForm.querySelector(
            'input[name="goldPrice"]'
        );
    }

    initSwiper() {
        var galleryThumbs = new Swiper(this.goldFeverSwiperTarget, {
            centeredSlides: true,
            slidesPerView: 3,
            touchRatio: 0.2,
            autoplay: {
                delay: 3500
            },
            slideToClickedSlide: true,
            loop: true,
            loopedSlides: 8,
            spaceBetween: 25,
            initialSlide: 3,
            breakpoints: {
                801: {
                    slidesPerView: 5,
                    spaceBetween: 75
                }
            },
            pagination: {
                el: this.swiperPaginationTarget,
                clickable: true
            },

            on: {
                slideChange: function() {
                    var activeIndex = this.activeIndex;
                    var slides = this.wrapperEl.children;
                    var numSlides = slides.length;
                    var previousIndex =
                        (activeIndex - 1 + numSlides) % numSlides;
                    var nextIndex = (activeIndex + 1) % numSlides;
                    var previousPreviousIndex =
                        (activeIndex - 2 + numSlides) % numSlides;
                    var nextNextIndex = (activeIndex + 2) % numSlides;

                    // Remove classes from all slides
                    for (var i = 0; i < slides.length; i++) {
                        slides[i].classList.remove("swiper-slide-next-next");
                        slides[i].classList.remove(
                            "swiper-slide-previous-previous"
                        );
                    }

                    var previousSlide = slides[previousIndex];
                    var nextSlide = slides[nextIndex];
                    var previousPreviousSlide = slides[previousPreviousIndex];
                    var nextNextSlide = slides[nextNextIndex];

                    previousPreviousSlide.classList.add(
                        "swiper-slide-previous-previous"
                    );
                    nextNextSlide.classList.add("swiper-slide-next-next");
                }
            }
        });
    }

    formatTextWithWhitespace(text) {
        var lines = text.split(/\r?\n/).map(function(line) {
            return line.trim();
        });
        var filteredLines = lines.filter(function(line) {
            return line !== "";
        });
        return filteredLines.join(" ");
    }

    formatPriceWithWhitespace(price) {
        return price.replace(/\s+/g, " ").trim();
    }

    buttonClick(event) {
        event.preventDefault();

        // Find closest element with class "swiper-slide"
        var swiperSlide = event.target.closest(".swiper-slide");

        // Find element name with class "js-item-name"
        var elementNameElement = swiperSlide.querySelector(".js-item-name");
        var elementName = elementNameElement
            ? elementNameElement.textContent
            : "";
        var formattedName = this.formatTextWithWhitespace(elementName);

        // Find element price with class "js-item-price"
        var elementPriceElement = swiperSlide.querySelector(".js-item-price");
        var elementPrice = elementPriceElement
            ? elementPriceElement.textContent
            : "";
        var formattedPrice = this.formatPriceWithWhitespace(elementPrice);

        console.log(
            "Ordered gold: " + formattedName + " with price: " + formattedPrice
        );

        let name = swiperSlide.querySelector(".js-item-name").dataset.itemName;
        let price = "";
        if (swiperSlide.querySelector(".js-item-price")) {
            price = swiperSlide.querySelector(".js-item-price").dataset
                .itemPrice;
        }

        this.goldNameInput.value = name;
        this.goldPriceInput.value = price;

        document.querySelector(".js-name-target").textContent = formattedName;

        const scrollY = this.goldForm.offsetTop;
        window.scrollTo({ top: scrollY, behavior: "smooth" });
    }
}
