import { Controller } from "@hotwired/stimulus";

// import Swiper bundle with all modules installed
import Swiper from "swiper-old/bundle";

// import styles bundle
import "swiper/css/bundle";

export default class extends Controller {
    static targets = ["thumbsSwiper", "textsSwiper", "swiperPagination"];

    connect() {
        /* this.initializeSwiper(this.companyManagmentSwiperWrapTarget); */
        this.initSwip();
    }

    initSwip() {
        var galleryThumbs = new Swiper(this.thumbsSwiperTarget, {
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 1,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            loop: true,
            loopedSlides: 2,
            effect: "coverflow",
            coverflowEffect: {
                stretch: 1,
                rotate: 10,
                scale: 0.85,
                slideShadows: false
            },
            initialSlide: 1,
            autoplay: {
                delay: 3500
            },

            breakpoints: {
                768: {
                    slidesPerView: 3
                }
            },
            pagination: {
                el: this.swiperPaginationTarget,
                clickable: true
            }
        });

        var textsSwiper = new Swiper(this.textsSwiperTarget, {
            spaceBetween: 10,
            loop: true,
            loopedSlides: 2,
            effect: "fade"
        });

        textsSwiper.controller.control = galleryThumbs;
        galleryThumbs.controller.control = textsSwiper;
    }
}
